.banner-item{
    display: flex;
    align-items: center;
    width: "100%";
    border: solid 1px grey;
    border-radius: 2px;
    padding: 5px;
    justify-content: space-between;
  }
  .banner-item-content{
    font-size: small;
  }
  .banner-title{
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
  }
  .btn-danger, .btn-primary,.btn-success{
    color: #fff;
    margin: 0 3px;
  }
  .banner-section {
    padding: 15px;
    margin: 5px;
    background-color: #fff;
    border-radius: 5px;
    font-size: 0.9rem;
  }
  .badge-warning, .banner-ineffect{
    background-color: #ffeaa7;
    border-color: #fdcb6e;
}
.badge-secondary, .banner-inactive{
    background-color: #dfe6e9;
    border-color: #636e72;
    color: black;
  }
  
  .btn-primary{
    background-color: #0984e3;
    border-color: #0984e3;
  }
  .flexContainer {
    display: flex;
    padding: 3px;
    align-items: center;
  }
  .flexContainer .badge{
    padding: 5px;
    margin: 0 5px;
  }
  .select-region{
    width: '100%'
  }