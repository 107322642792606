.bi-search {
  margin: 0 5px;
}
.search-form {
    display: block;
    width: 100%;
}
.search-input {
    width: 50%;
    display: inline-block;
    vertical-align: baseline;
}
.search-action {
    width: 40%;
    display: inline-block;
    vertical-align: bottom;
}
.react-bootstrap-table table {
    margin-bottom: 0;
}
.react-bootstrap-table{
    border: solid 1px grey;
    margin-bottom: 1rem;
    margin-top: 5px;
}