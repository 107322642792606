@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

.react-bootstrap-table {
    max-height: 500px;
    overflow: auto;
  }
  .flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .flex-item-100 {
    flex: 100%;

  }
  .flex-item-50 {
    flex: 50%;
    flex-wrap: nowrap;
  }